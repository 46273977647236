<template>
  <div class="message-container h-100">

    
    <div class="message-title">
      {{ $t("subscription_price_increase_message_title") }}
    </div>
    
    <div >
      <p v-html="$t('subscription_price_increase_message_html')" class="message-text"></p>
      <span class="message-signature">{{ $t("subscription_price_increase_message_signature") }}</span>
    </div>
  </div>
</template>

<script>
import LayoutDefault from '@/layouts/LayoutDefault'
import LayoutSideBar from '@/layouts/LayoutSideBar'

import { mapActions, mapGetters } from "vuex";

export default {
  name: 'MessageSubscriptionPrices',
  methods: {
    ...mapActions('users', ['setIsWebView', 'setToken'])
  },
  computed: {
    ...mapGetters('users', ['isWebView']),
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
  },
  created() {
    let parameters = this.$route.query;
    if (parameters['webview']) {
      this.setIsWebView(parameters['webview'] == 'true');
    }
  },
  async mounted(){
    let parameters = this.$route.query;
    if (parameters['token']) {
            let token = parameters['token'];
            this.setToken(token);
    }
    this.$emit('update:layout', this.isWebView ? LayoutDefault : LayoutSideBar);
  }  
}
</script>

<style lang="scss" scoped>
.message-container{
  width: 100vw;
  padding: 2rem 1rem;
  overflow: scroll;
  @media screen and (min-width: 768px) {
    width: auto;
  }
}

.message-title{
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.message-text{
  font-size: 0.8rem;
}

.message-list{
  list-style-position: inside;
  margin-top: 1rem;
  li{
    font-size: 0.8rem;
  }
}

.message-signature{
  color: #5B74F4;
  font-weight: 700;
}

.message-strong{
  font-weight: 700;
  font-size: 0.8rem;
}

strong{
  font-size: 0.8rem;
}

.link-back{
  margin-bottom: 2rem;
  color: #5B74F4;
  text-decoration: underline;
}
</style>
